<form>

    <div fxLayoutGap="16px">
        <span>
            <a mat-button aria-label="Project">Project:</a>
        </span>
        <span>
            <mat-form-field required>
                <mat-select id="id_project" [formControl]="projectCtrl" [compareWith]="compareProjectIds">
                    <mat-option *ngFor="let proj of projectsObs | async" [value]="proj">
                        {{ proj.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </span>
        <span *ngIf="projectCtrl?.value?.permissions?.add_project">
            <button mat-mini-fab (click)="openCreateProjectDialog()" color="primary" matTooltip="Create New Project">
                <mat-icon>add</mat-icon>
            </button>
        </span>
    </div>

    <br/><br/>

    <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="start stretch" fxLayoutGap="32px">
        <mat-card [ngClass]="chart.app_healthy ? 'chart-card-enabled': 'chart-card-disabled'"
                  fxFlex="0 1 calc(20% - 32px)" class="chart-card"
                  *ngFor="let chart of chartObs | async; trackBy: trackByChartId" appMaterialElevation>
            <mat-card-header>
                <img mat-card-avatar src="{{chart.install_template?.icon_url}}"
                     *ngIf="chart.install_template?.icon_url"/>
                <span *ngIf="!chart.install_template?.icon_url" mat-card-avatar>
                    <mat-icon>apps</mat-icon>
                </span>
                <mat-card-title>{{chart.install_template?.display_name || chart.display_name}}</mat-card-title>
                <mat-card-subtitle>{{chart.install_template?.maintainers || "&nbsp;"}}</mat-card-subtitle>
                <span class="flex-spacer"></span>
                <button mat-icon-button aria-label="Overview" style="margin: 0 -16px 16px -16px">
                    <mat-icon [matMenuTriggerFor]="chartMenu">more_vert</mat-icon>
                </button>
                <mat-menu #chartMenu="matMenu">
                    <button *ngIf="chart.name=='galaxy' && chart.permissions.change_chart; else place_holder_configure"
                            mat-menu-item matTooltip="Configure {{ chart.display_name }}"
                            (click)="openChartReconfigurationDialog(chart)">
                        <mat-icon>edit</mat-icon> Settings
                    </button>
                    <ng-template #place_holder_configure>
                        <button mat-menu-item disabled>
                            <mat-icon>edit</mat-icon> Settings
                        </button>
                    </ng-template>
                    <button mat-menu-item matTooltip="Upgrade {{ chart.display_name }}" disabled="true">
                        <mat-icon>upgrade</mat-icon> Upgrade
                    </button>
                    <button *ngIf="chart.permissions.change_chart; else place_holder_rollback" mat-menu-item
                            matTooltip="Rollback to previous revision" (click)="rollbackChart(chart)">
                        <mat-icon>undo</mat-icon> Rollback
                    </button>
                    <ng-template #place_holder_rollback>
                        <button mat-menu-item disabled>
                            <mat-icon>undo</mat-icon> Rollback
                        </button>
                    </ng-template>
                </mat-menu>
            </mat-card-header>
            <a href="{{ getAppURL(chart.values) }}" target="_blank">
                <img mat-card-image
                     src="{{chart.install_template?.screenshot_url || chart.install_template?.icon_url || 'assets/images/no-image-available.png'}}"
                     alt="Access {{chart.display_name}}" class="chart-image"/>
                <mat-progress-bar class="progress"
                                  [mode]="chart.app_healthy ? 'determinate': 'indeterminate'"
                                  value="100"
                                  [ngClass]="chart.app_healthy ? 'green-progress': ''"></mat-progress-bar>
            </a>
            <mat-card-content class="card-content">
                <p [innerHTML]="chart.install_template?.summary"></p>
            </mat-card-content>
        </mat-card>
    </div>

    <br/><br/>

    <button mat-flat-button color="primary" (click)="openAddChartDialog()"
            *ngIf="projectCtrl?.value?.permissions?.change_project">
        <mat-icon>add</mat-icon>
        Add Application
    </button>
</form>
